<template>
  <v-dialog v-model="open"
            width="1200px"
            @click:outside="clearForm()"
            @keydown.esc="clearForm()">
    <v-card class="account-dialog">
      <v-card-title>{{ title }}</v-card-title>
      <v-divider class="elevation-3"></v-divider>
      <v-card-text>
        <v-row class="my-2">
          <crm-table-pageable-new
              :headers="headers"
              :data="accountProducts"
              :config="config"
              :loaded-options="options"
              :filters-dictionaries="filtersDictionaries"
              :available-filters.sync="availableFilters"
              :total-elements="totalElements"
              :total-pages="totalPages"
              item-key="id"
              tableWrapperMinHeight="calc(100vh - 200px)"
              @onChangeData="onChangeData"
          />
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="account-dialog__actions">
        <v-btn text class="account-dialog__actions--cancel" @click="clearForm()">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CrmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';

export default {
  name: 'AccountDialog',
  components: {
    CrmTablePageableNew,
  },
  props: {
    open: {
      required: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    accountId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'orderNumber', text: 'Номер заказа', type: 'defaultItem', sortable: false,
        },
        {
          value: 'product', text: 'Наименование товара', type: 'defaultItem', sortable: false,
        },
        {
          value: 'sum', text: 'Сумма', type: 'defaultItem', sortable: false,
        },
        {
          value: 'bonuses', text: 'Бонус', type: 'defaultItem', sortable: false,
        },
        {
          value: 'percentage', text: '%', type: 'defaultItem', sortable: false,
        },
        {
          value: 'status', text: 'Статус', type: 'defaultItem', sortable: false,
        },
        {
          value: 'created', text: 'Дата создания', type: 'defaultItem', sortable: false,
        },
      ],
      accountProducts: [],
      options: {
        page: 1,
        size: 10,
      },
      config: {
        search: true,
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'statuses', name: 'Статус', type: 'select', active: true,
        },
      ],
    };
  },
  async created() {
    await this.getStatuses();
    this.$loading(false);
    this.onChangeData(this.options);
  },
  methods: {
    getStatuses() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$billing.get('BonusProductOperation/BonusStatuses').then((res) => {
          this.appealStatuses = res;
          this.$set(this.filtersDictionaries, 'statuses', res.map((i) => ({
            value: i.id,
            label: i.name,
          })));
          resolve();
        });
      });
    },
    clearForm() {
      this.$emit('close');
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.options = { ...resultOptions };
      this.getAccountInfo();
    },
    getAccountInfo() {
      this.$loading(true);
      this.$billing.get('bonusProductOperation', {
        params: {
          AccountId: this.accountId,
          PageNumber: this.options.page,
          PageSize: this.options.size,
          StatusIds: this.options.statuses,
          Sorting: this.options.sort,
          'Search.Value': this.options.search,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / (this.options.size || 1));
        this.accountProducts = response.data.map((item) => ({
          ...item,
          created: item.created ? this.$moment(item.created).format('DD-MM-YYYY HH:mm') : item.created,
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.account-dialog {
  &__actions {
    display: flex;
    justify-content: end;
    &--cancel {
      color: #007450;
      border-radius: 20px;
    }
  }
}
</style>
