<template>
  <v-container fluid class="accounts">
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :search-placeholder="config.searchPlaceholder"
            :loaded-options="options"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            :total-elements="totalElements"
            :total-pages="totalPages"
            @handleActions="handle"
            @onChangeData="onChangeData"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
    <AccountDialog v-if="accountDialogOpened"
                   :open="accountDialogOpened"
                   :title="dialogTitle"
                   :account-id="accountId"
                   @close="closeDialog" />
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import moment from 'moment';
import AccountDialog from './components/AccountDialog.vue';

export default {
  name: 'accounts',
  components: {
    AccountDialog,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'account', sortable: false, text: 'Номер счета', type: 'defaultItem',
        },
        {
          value: 'created', sortable: true, text: 'Дата создания', type: 'defaultItem',
        },
        {
          value: 'fullname', sortable: false, text: 'Покупатель', type: 'defaultItem',
        },
        {
          value: 'balance', sortable: false, text: 'Баланс', type: 'defaultItem',
        },
        {
          value: 'onHold', sortable: false, text: 'В ожидании', type: 'defaultItem',
        },
      ],
      data: [],
      config: {
        search: true,
        pageable: true,
      },
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: true,
        },
      ],
      totalElements: 0,
      totalPages: 0,
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
      },
      exportLoading: false,
      accountDialogOpened: false,
      dialogTitle: '',
      accountId: '',
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    handle(element) {
      switch (element.type) {
        case 'info':
          this.openAccountDialog(element.item);
          break;
        default:
          break;
      }
    },
    openAccountDialog(item) {
      this.dialogTitle = item.account;
      this.accountId = item.id;
      this.accountDialogOpened = true;
    },
    closeDialog() {
      this.dialogTitle = '';
      this.accountId = '';
      this.accountDialogOpened = false;
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getAccounts();
    },
    getAccounts() {
      const params = this.$route.query;

      this.$loading(true);
      this.$billing.get('BonusAccount', {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          Sorting: params.sort,
          DateFrom: params.createdDateRange && params.createdDateRange.split(',')[0],
          DateUntil: params.createdDateRange && params.createdDateRange.split(',')[1],
          'Search.Value': params.search,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / response.pageSize);
        this.data = response.data.map((i) => ({
          ...i,
          created: i.created ? moment(i.created).format('DD.MM.YYYY HH:mm') : i.created,
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
  },
};
</script>

<style lang="scss">
.accounts {

}
</style>
